import { AppBar, Box, styled, Container } from "trends/fabrics";
import ThemeProvider from "firms/theme/ThemeProvider";
import AtlasDiscovery from "nodes/AtlasDiscovery";
import AtlasAvatar from "nodes/AtlasAvatar";
import AtlasBottomToolbar from "nodes/AtlasBottomToolbar";
import AtlasGrowGlobe from "nodes/AtlasGrowGlobe";

const BottomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  top: "auto",
  bottom: 0,
}));

const Atlas = () => (
  <ThemeProvider>
    <Container sx={{ my: 2 }} maxWidth="xs">
      <AtlasAvatar />
      <Box py={2} />
      <AtlasDiscovery />
      <AtlasGrowGlobe />
      <BottomAppBar position="fixed">
        <AtlasBottomToolbar />
      </BottomAppBar>
    </Container>
  </ThemeProvider>
);

export default Atlas;
