import { Stack, Typography, Toolbar } from "trends/fabrics";

const AtlasBottomToolbar = () => (
  <Toolbar sx={{ justifyContent: "center" }}>
    <Stack>
      <Typography textAlign="center" color="text.disabled" variant="caption">
        © 2024-{new Date().getFullYear()} Mizony, Inc.
      </Typography>
      <Typography textAlign="center" color="text.disabled" variant="caption">
        All Rights Reserved.
      </Typography>
    </Stack>
  </Toolbar>
);

export default AtlasBottomToolbar;
