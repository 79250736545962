import { Link, Box, Stack, Button, Typography } from "trends/fabrics";

const AtlasDiscovery = () => (
  <Stack gap={1}>
    <Typography variant="body2" color="text.secondary">
      Introducing a creative way to visualize information, a card platform
      domain{" "}
      <Typography
        color="secondary"
        component={Link}
        href="https://memoir.cards"
        variant="body2"
      >
        memoir.cards
      </Typography>{" "}
      to organize memories with cards.
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Please let us know your thoughts as you publish your first card in the
      public domain.
    </Typography>
    <Box alignSelf="center" mt={1}>
      <Button
        color="secondary"
        variant="outlined"
        component={Link}
        href="https://memoir.cards"
      >
        Card Memoir
      </Button>
    </Box>
  </Stack>
);

export default AtlasDiscovery;
